import React from 'react';
import phf from '../../assets/project.jpg'
import campo from '../../assets/campo.jpg'
import construcao from '../../assets/construcao.jpg'


const Project = () => {
    return (
        <div className="flex flex-col items-center mt-20">
    <hr className="w-1/2 border-t border-gray-300 mb-4" />
    <label className="text-xl font-semibold">Projetos</label>
    <hr className="w-1/2 border-t border-gray-300 mt-4" />

    <div className="flex mt-20 space-x-6">
        <div className="bg-white shadow-lg rounded-md overflow-hidden">
            <img src={phf} alt="Projeto 1" className="w-full h-64 object-cover" />
            <div className="p-4">
                <p className="text-center font-semibold">Arquitetura</p>
            </div>
        </div>

        <div className="bg-white shadow-lg rounded-md overflow-hidden">
            <img src={construcao} alt="Projeto 2" className="w-full h-64 object-cover" />
            <div className="p-4">
                <p className="text-center font-semibold">Construção Civil</p>
            </div>
        </div>

        <div className="bg-white shadow-lg rounded-md overflow-hidden">
            <img src={campo} alt="Projeto 3" className="w-full h-64 object-cover" />
            <div className="p-4">
                <p className="text-center font-semibold">Estrutura de Campo</p>
            </div>
        </div>
    </div>
</div>
    );
};

export default Project;
