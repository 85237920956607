import React from 'react';
import logo from '../../assets/logoBranca.png'

const Footer = () => {
    return (
      <footer className="bg-gray-800 text-white py-8">
      <div className="flex justify-between items-center max-w-6xl mx-auto px-6">
          <div className="flex items-center">
              <img src={logo} alt="Logo da Empresa" className="w-16 h-auto mr-4" />
              <div>
                  <p className="font-normal">PHF PROJETOS LTDA</p>
                  <p>50.816.999/0001-10</p>
              </div>
          </div>
          <p className="font-poppins text-sm flex items-center">
              <span className="text-center">
                  Rua Camprestre, Nº 132,<br />
                  Julia Caparroz<br />
                  Catanduva/SP<br /> 
                  CEP 15804-367
              </span>
          </p>
          <p className="font-poppins text-sm">Desenvolvido por <a href='https://www.linkedin.com/in/l%C3%ADvia-marcela-08002320b/' target='_blank'>Lívia</a></p>
      </div>
  </footer>  
    );
};

export default Footer;
