import React from 'react'
import logo from '../../assets/logoPreta.png'
import { Link } from "react-router-dom";

function Header(){
  return (
    <header className="fixed top-0 bg-opacity-75 bg-white p-0 w-full flex justify-between items-center shadow-md">
    <div className="ml-4">
        <img src={logo} alt="Logo da Empresa" className="w-16 h-auto" />
    </div>
    <nav className="mr-4">
        <ul className="flex space-x-6">
            <li><a href="#" className="text-gray-800 font-semibold hover:text-gray-600">Home</a></li>
            <li><a href="#" className="text-gray-800 font-semibold hover:text-gray-600">Projetos</a></li>
            <li><a href="#" className="text-gray-800 font-semibold hover:text-gray-600">Sobre Nós</a></li>
            <li><a href="#" className="text-gray-800 font-semibold hover:text-gray-600">Login</a></li>
        </ul>
    </nav>
</header>
  )
}

export default Header
