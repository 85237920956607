import './App.css';
import Header from './components/Header/Header.tsx';
import MainBack from './components/Main/Main.tsx'
import Project from './components/Project/Project.tsx'
import AboutUs from './components/About Us/About Us.tsx'
import Footer from './components/Footer/Footer.tsx'

function App() {
  return (
  <div>
  <Header />
  <MainBack />
  <Project />
  <AboutUs />
  <Footer />
   </div>
  );
}

export default App;
