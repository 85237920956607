import React from 'react';
import logo from '../../assets/logoPreta.png'

const AboutUs = () => {
    return (
      <div className="flex flex-col items-center mt-20">
    <hr className="w-1/2 border-t border-gray-300 mb-4" />
    <label className="text-xl font-semibold">Sobre Nós</label>
    <hr className="w-1/2 border-t border-gray-300 mt-4" />

    <div className="flex justify-center items-center mt-4">
        <img src={logo} alt="Logo da Empresa" className="w-40 h-auto ml-20 mr-10" /> 
        <p className="font-poppins text-sm text-gray-700 whitespace-normal text-left mb-8">
        A PHF projetos, é mais do que uma empresa de projetos. Somos criadores, inovadores e solucionadores de problemas.<br></br> Somos apaixonados pelo poder da imaginação e da engenharia para transformar ideias em realidade. <br /><br />
        <strong>Nossos Serviços: </strong><br></br> Oferecemos uma ampla gama de serviços de projeto, desde projetos civis de engenharia até georreferenciamento de imóveis rurais<br></br>
        e urbanos, e consultoria de projetos para a sua empresa. Nossa experiência abrange setores diversos, incluindo construção civil, infraestrutura,<br></br> energia, meio ambiente e muito mais. <br /><br />
       <strong> Por Que Escolher a PHF? </strong><br />
        • Experiência Comprovada: Com tempo de experiência no setor, nossa equipe tem um histórico comprovado de sucesso em projetos desafiadores. <br />
        • Inovação Constante: Abraçamos a inovação e estamos sempre atualizados com as últimas tendências e tecnologias do setor. <br />
        • Compromisso com o Cliente: Valorizamos nossos relacionamentos com os clientes e trabalhamos incansavelmente para superar suas expectativas. <br /><br />
        Na PHF projetos, transformamos visões em realidade. Se você está buscando um parceiro de projeto confiável e dedicado, entre em contato conosco hoje mesmo. Estamos ansiosos para colaborar com você na próxima jornada de criação e inovação.
    </p>
</div>
  </div>
    );
};

export default AboutUs;
