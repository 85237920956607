import React from 'react';
import fundo from '../../assets/engenhariaFundo.jpg';

const MainBack = () => {
    return (
        <div
    className="min-h-screen bg-cover bg-center flex justify-center items-center"
    style={{ backgroundImage: `url(${fundo})` }}
>
<div className="bg-white p-6 shadow-lg rounded-xl ml-auto mr-10 flex flex-col justify-center items-center">
    <h2 className="text-lg font-semibold mb-4 font-poppins">Entre em contato e faça seu orçamento</h2>
    <button
        className="font-poppins text-md bg-green-500 text-white rounded-full py-2 px-6 hover:bg-green-400 transition duration-300"
        onClick={() => window.open("https://wa.me/5517996471793", "_blank")}
    >
        Whatsapp
    </button>
    <label className="mt-4 block font-poppins text-lg">Já é parceiro:</label>
    <button
        className="font-poppins text-md bg-gray-300 text-gray-800 rounded-full py-2 px-6 mt-2 hover:bg-gray-200 transition duration-300"
    >
        Faça o login
    </button>
</div>
</div>
    );
};

export default MainBack;
